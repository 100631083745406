@charset "UTF-8";
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.10.0/css/all.min.css);
@import url(https://cdn.jsdelivr.net/npm/bootstrap-icons@1.4.1/font/bootstrap-icons.css);

/*LIB*/
@import url("./bootstrap.min.css");
@import url('../lib/animate/animate.min.css');
@import url("../lib/owlcarousel/assets/owl.carousel.min.css");
@import url("../lib/lightbox/css/lightbox.min.css");

/********** Template CSS **********/
:root {
  --primary: #ff353f;
  --secondary: #ff6b71;
  --light: #F6F4F9;
  --dark: #04000B;
}

.dark-mode {
  --color: #ebf4f1;
  --color-primary: #ff353f;
  --color-secondary: #fdf9f3;
  --bg: #091a28;
  --bg-secondary: #071521;
  --border-color: #0d2538;
}

.sepia-mode {
  --color: #433422;
  --color-secondary: #504231;
  --bg: #f1e7d0;
  --bg-secondary: #eae0c9;
  --border-color: #ded0bf;
}

/* Styles Scrollbar*/
::-webkit-scrollbar {
  width: 8px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background: var(--primary);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--secondary);
}

::-webkit-scrollbar-track {
  background: #fff;
  border: #e3e0e0 solid 1px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  background-color: var(--bg);
  color: var(--color);
  transition: background-color .3s;
}

a {
  color: var(--primary);
  text-decoration: none;
  list-style: none;
}

a:hover {
  color: var(--secondary);
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

ul li {
  display: inline-block;
  padding: 5px;
}

p {
  margin: 0;
  padding-top: 5px;
  padding-bottom: 20px;
}

/*** Heading ***/
h1,
h2,
.fw-bold {
  font-weight: 700 !important;
}

h3,
h4,
.fw-semi-bold {
  font-weight: 600 !important;
}

h5,
h6,
.fw-medium {
  font-weight: 500 !important;
}

.my-6 {
  margin-top: 6rem;
  margin-bottom: 6rem;
}

.py-6 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.br {
  border-radius: 15px;
}

/*** Button ***/
.btn {
  font-weight: 500;
  transition: .5s;
}

.btn:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, .5);
}

.btn-square {
  width: 38px;
  height: 38px;
}

.btn-sm-square {
  width: 32px;
  height: 32px;
}

.btn-lg-square {
  width: 48px;
  height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
  border-radius: 50px;
}

img {
  max-width: 100%;
  height: auto;
}

.logo {
  width: 20%;
  padding: 1em;
  display: flex;
  justify-content: space-around;
  align-items: center;
  object-fit: contain;
}

@media (max-width: 600px) {
  .logo {
    width: 50%;
  }
}

/*** Spinner ***/
#spinner {
  opacity: 0;
  visibility: hidden;
  transition: opacity .5s ease-out, visibility 0s linear .5s;
  z-index: 99999;
}

#spinner.show {
  transition: opacity .5s ease-out, visibility 0s linear 0s;
  visibility: visible;
  opacity: 1;
}

/*********Back To Top *********/
.back-to-top {
  position: fixed;
}

.icon-position {
  position: fixed;
  bottom: 40px;
  right: 25px;
  z-index: 99;
}

.icon-style {
  background-color: var(--primary);
  border: 2px solid #fff;
  border-radius: 5px;
  height: 50px;
  width: 50px;
  padding: 9px;
  color: #fff;
  cursor: pointer;
  animation: movebtn 3s ease-in-out infinite;
  transition: all .5s ease-in-out;
}

.icon-style:hover {
  animation: none;
  background: transparent;
  color: var(--bs-white);
  border: 2px solid var(--bs-white);
}

@keyframes movebtn {
  0% {
      transform: translateY(0px);
  }

  25% {
      transform: translateY(20px);
  }

  50% {
      transform: translateY(0px);
  }

  75% {
      transform: translateY(-20px);
  }

  100% {
      transform: translateY(0px);
  }
}



/*** Navbar ***/
.navbar {
  background-color: transparent;
}

.navbar-nav .text-primary {
  color: var(--secondary) !important;
}

.navbar .dropdown-toggle::after {
  border: none;
  content: "\f067";
  font-family: "Font Awesome 5 Free";
  font-size: 10px;
  font-weight: bold;
  vertical-align: middle;
  margin-left: 8px;
}

.navbar-light .navbar-nav {
  position: relative;
  margin-right: 25px;
  padding: 35px 0;
  font-family: 'Jost', sans-serif;
  font-size: 18px;
  font-weight: 500;
  color: var(--light) !important;
  outline: none;
  transition: .5s;
}


.sticky-top.navbar-light .navbar-nav .nav-link {
  padding: 20px 0;
  position: relative;
  margin-right: 25px;
  font-family: 'Jost', sans-serif;
  font-size: 18px;
  font-weight: 400;
  color: var(--bs-gray) !important;
  outline: none;
  transition: .5s;
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link.active {
  color: var(--secondary) !important;
}


.navbar-light .navbar-brand h1 {
  color: var(--primary);
}

.navbar-light .navbar-brand img {
  max-height: 60px;
  transition: .5s;
}

.sticky-top.navbar-light .navbar-brand img {
  max-height: 45px;
}

@media (max-width: 991.98px) {
  .navbar{
    background-color: #fff;
  }
  
  .sticky-top.navbar-light {
    position: relative;
    background: #ffffff !important;
  }

  .navbar-light .navbar-collapse {
    margin-top: 15px;
    border-top: 1px solid #DDDDDD;
  }

  .navbar-light .navbar-nav .nav-link,
  .sticky-top.navbar-light .navbar-nav .nav-link {
    padding: 10px 0;
    margin-left: 0;
    color: var(--dark) !important;
  }

  .navbar-light .navbar-brand h1 {
    color: var(--primary);
  }

  .navbar-light .navbar-brand img {
    max-height: 45px;
  }
}

@media (min-width: 992px) {
  .navbar-light {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    border-bottom: 1px solid rgba(256, 256, 256, .1);
    z-index: 999;
  }

  .sticky-top.navbar-light {
    position: fixed;
    background: var(--light);
  }

  .sticky-top.navbar-light .navbar-brand h1 {
    color: var(--primary);
  }

  .navbar-light .navbar-nav .nav-item .dropdown-menu {
    display: block;
    border: none;
    margin-top: 0;
    top: 150%;
    opacity: 0;
    visibility: hidden;
    transition: .5s;
  }

  .navbar-light .navbar-nav .nav-item:hover .dropdown-menu {
    top: 100%;
    visibility: visible;
    transition: .5s;
    opacity: 1;
  }

  .navbar .nav-item:hover .dropdown-menu {
    transform: rotateX(0deg);
    visibility: visible;
    transition: .5s;
    opacity: 1;
  }

  .navbar-light .btn {
    color: var(--dark);
    background: #FFFFFF;
  }

  .sticky-top.navbar-light .btn {
    color: var(--dark);
    background: var(--bs-white);
  }
}

/***********Menu Animado********/
.pet {
  margin-top: 0px;
  background-color: transparent;
  background-image: url(../imagens/1.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  opacity: 0.5;
}

.hero-header {
  width: 100%;
  height: 100vh;
  background-color: transparent;
  background-image: url(../imagens/vitrine.png);
  background-size: cover;
  background-repeat: no-repeat;
}

#menuNav {
  float: left;
  background-image: url(../imagens/sinal-2.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.stage2 {
  top: 18em;
  left: -15em;
}

.stage {
  float: left;
  height: 350px;
  width: 250px;
  margin-left: auto;
  margin-right: auto;
}

.p {
  color: var(--color) !important;
  font-size: 30px;
  font-weight: 500;
  text-align: center;
  margin-top: 0;
  cursor: pointer;
  width: 100%;
  border-radius: 10px;
  font-family: Century Gothic;
  letter-spacing: 5px;
  background-image: url(../imagens/p-1.png);
  background-size: contain;
  background-repeat: no-repeat;
}

.menu {
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
  width: 200px;
  height: 40px;
  margin-top: 5%;
  transition-duration: 0.2s;
}

.p:hover {
  background-image: url(../imagens/p-1.png);
  background-repeat: no-repeat;
  background-size: contain;
  color: black;
}

.menu:nth-child(odd) {
  transform: perspective(300px) rotateY(45deg);
}

.menu:nth-child(even) {
  transform: perspective(300px) rotateY(-45deg);
}

.menu:hover {
  transform: rotateY(0);
  background: transparent;
  color: black;
  box-shadow: 0px 0px 0px;
}

/***********Header********/
.header {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 3em;
}

.header img {
  opacity: 0.5;
  margin-top: -9em;
}



/************Responsive****************/
@media (max-width: 600px) {
  .header {
    display: flex;
    flex-direction: column;
    margin: 1em;
  }

  .p {
    width: 100%;
  }

  img {
    max-width: 100%;
  }

  .stage {
    height: 350px;
    width: 250px;
  }

  .navbar-light {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    text-align: center;
    border-bottom: 1px solid rgba(256, 256, 256, .1);
    z-index: 999;
  }

  .hero-header {
    height: 100%;
  }

  .pet {
    width: 100%;
  }
}

/*** Section Title ***/
.section-title {
  position: relative;
  display: flex;
  align-items: center;
  font-weight: 500;
  text-transform: uppercase;
}

.section-title span:first-child,
.section-title span:last-child {
  position: relative;
  display: inline-block;
  margin-right: 30px;
  width: 30px;
  height: 2px;
}

.section-title span:last-child {
  margin-right: 0;
  margin-left: 30px;
}

.section-title span:first-child::after,
.section-title span:last-child::after {
  position: absolute;
  content: "";
  width: 15px;
  height: 2px;
  top: 0;
  right: -20px;
}

.section-title span:last-child::after {
  right: auto;
  left: -20px;
}

.section-title.text-primary span:first-child,
.section-title.text-primary span:last-child,
.section-title.text-primary span:first-child::after,
.section-title.text-primary span:last-child::after {
  background: var(--primary);
}

.section-title.text-secondary span:first-child,
.section-title.text-secondary span:last-child,
.section-title.text-secondary span:first-child::after,
.section-title.text-secondary span:last-child::after {
  background: var(--secondary);
}

.section-title.text-white span:first-child,
.section-title.text-white span:last-child,
.section-title.text-white span:first-child::after,
.section-title.text-white span:last-child::after {
  background: #FFFFFF;
}



/*** Header ***/
.carousel-caption,
.carousel {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: start;
  z-index: 1;
}

.carousel__prev,
.carousel__netx,
.carousel-control-prev,
.carousel-control-next {
  width: 15% !important;
}

.carousel__prev .carousel__icon,
.carousel__next .carousel__icon,

.carousel-control-prev-icon,
.carousel-control-next-icon {
  width: 3rem !important;
  height: 3rem !important;
  background-color: var(--primary);
  color: white;
  border: 10px solid var(--primary);
  border-radius: 3rem !important;
}

@media (max-width: 768px) {
  #header-carousel .carousel-item {
    position: relative;
    min-height: 450px;
  }

  #header-carousel .carousel-item img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.page-header {
  padding-top: 12rem;
  padding-bottom: 6rem;
  background: url("../imagens/2.png");
  background-position: center top, center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.breadcrumb-item+.breadcrumb-item::before {
  color: #999999;
}

/*** Section Header ***/
.section-header {
  position: relative;
  padding-top: 25px;
}

.section-header::before {
  position: absolute;
  content: "";
  width: 60px;
  height: 2px;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background: var(--primary);
}

.section-header::after {
  position: absolute;
  content: "";
  width: 90px;
  height: 2px;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  background: var(--secondary);
}

.section-header.text-start::before,
.section-header.text-start::after {
  left: 0;
  transform: translateX(0);
}

/*** About ***/
.about-img img {
  position: relative;
  z-index: 2;
}

.about-img::before {
  position: absolute;
  content: "";
  top: 0;
  left: -50%;
  width: 100%;
  height: 100%;
  background-image: -webkit-repeating-radial-gradient(#FFFFFF, #EEEEEE 5px, transparent 5px, transparent 10px);
  background-image: -moz-repeating-radial-gradient(#FFFFFF, #EEEEEE 5px, transparent 5px, transparent 10px);
  background-image: -ms-repeating-radial-gradient(#FFFFFF, #EEEEEE 5px, transparent 5px, transparent 10px);
  background-image: -o-repeating-radial-gradient(#FFFFFF, #EEEEEE 5px, transparent 5px, transparent 10px);
  background-image: repeating-radial-gradient(#FFFFFF, #EEEEEE 5px, transparent 5px, transparent 10px);
  background-size: 20px 20px;
  transform: skew(20deg);
  z-index: 1;
}

/*------------------------------------------------
                        FAQ
-------------------------------------------------*/
.faq .faq-list {
  padding: 0 100px;
}

.faq .faq-list ul {
  padding: 0px;
  list-style: none;
}

.faq .faq-list li+li {
  margin-top: 15px;
}

.faq .faq-list li {
  padding: 10px;
  background: #fff;
  border-radius: 4px;
  position: relative;
}

.faq .faq-list a {
  display: block;
  position: relative;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  padding: 0 50px;
  outline: none;
  cursor: pointer;
  text-decoration: none;
}

.faq .faq-list .icon-help {
  font-size: 24px;
  position: absolute;
  right: 0;
  left: 20px;
  color: #e82c88;
}

.faq .faq-list .icon-show,
.faq .faq-list .icon-close {
  font-size: 24px;
  position: absolute;
  right: 0;
  top: 0;
}

.faq .faq-list p {
  margin-bottom: 0;
  padding: 10px 0 0 0;
}

.faq .faq-list .icon-show {
  display: none;
}

.faq .faq-list a.collapsed {
  color: var(--primary);
  transition: 0.3s;
}

.faq .faq-list a.collapsed:hover{
  color: var(--secondary);
}

.faq .faq-list a.collapsed .icon-show {
  display: inline-block;
}

.faq .faq-list a.collapsed .icon-close {
  display: none;
}

@media (max-width: 1200px) {
  .faq .faq-list {
    padding: 0;
  }
}

.faq .faq-list{
  width: 100%;
  margin: 5px;
  padding: 5px;
}


/*************FAQ*********************/
.faq-container {
  max-width: 600px;
  margin: 0 auto;
  border-radius: 10px;
  background-color: #fff;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.faq {
  box-sizing: border-box;
  background: transparent;
  padding: 30px;
  position: relative;
  overflow: hidden;
}

.faq:not(:first-child) {
  border-top: 1px solid #e6e6e6;
}

.faq-title {
  margin: 0 35px 0 0;
}

.faq .text-primary {
  color: var(--primary) !important;
}

.faq-text {
  color: var(--secondary);
  margin: 30px 0 0;
  display: none;
  line-height: 1.5rem;
}

.faq.active {
  background-color: var(--color);
  box-shadow: inset 4px 0px 0px 0px var(--accent-color);
}

.faq.active .faq-title {
  color: var(--accent-color);
}

.faq.active .faq-text {
  display: block;
  color: var(--bs-gray);
}

.faq-toggle {
  background-color: transparent;
  border: 1px solid #e6e6e6;
  color: inherit;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  padding-top: 3px;
  position: absolute;
  top: 30px;
  right: 30px;
  height: 30px;
  width: 30px;
  transition: 0.3s ease;
}

.faq-toggle:focus {
  outline: none;
}

.faq.active .faq-toggle {
  transform: rotate(180deg);
  background-color: var(--primary);
  border-color: var(--secondary);
  color: #fff;
}

/*** Project Portfolio ***/
#portfolio-flters li {
  display: inline-block;
  font-weight: 500;
  color: var(--dark);
  cursor: pointer;
  transition: .5s;
  border-bottom: 2px solid transparent;
}

#portfolio-flters li:hover,
#portfolio-flters li.active {
  color: var(--primary);
  border-color: var(--primary);
}

.portfolio-item img {
  transition: .5s;
}

.portfolio-item:hover img {
  transform: scale(1.1);
}

.portfolio-item .portfolio-overlay {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(179, 77, 121, 0.9);
  transition: .5s;
  opacity: 0;
}

.portfolio-item:hover .portfolio-overlay {
  opacity: 1;
}




/**********Views Info ***************/
.views-info {
  width: 100%;
  height: 75vh;
  background:
    url(../imagens/banner.jpg);
  background-position:
    center top,
    center center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

@media (max-width: 600px) {
  .views-info {
    height: 100%;
  }
}

/****Banner******/
.newsletter {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 350px;
  padding: 5em;
}

.newsletter .text-primary {
  color: var(--primary) !important;
}


/*------------------------------------------
PAGE GALLERY
--------------------------------------------*/

.swiper-gallery img {
  width: 100%;
  height: 100%;
}

/*---------------------------------------
  GALLERY        
-----------------------------------------*/
.bg-section-color {
  background: linear-gradient(98deg, var(--secondary), var(--primary) 94%);
}

.gallery .gallery-item img {
  transition: all .4s ease-in-out;
}

.gallery-item {
  position: relative;
  display: block;
}

.gallery-item .gallery-text {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.523);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 100px !important;
  opacity: 0;
  transition: .5s;
}

.gallery-item:hover .gallery-text {
  opacity: 1;
  padding-bottom: 20px !important;
}

/*** Product ***/
.nav-pills{
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-pills .nav-item .btn {
  color: var(--dark);
}

.nav-pills .nav-item .btn:hover,
.nav-pills .nav-item .btn.active {
  color: #FFFFFF;
}

/**************Blog***********/
.product-item {
  background: #FFFFFF !important;
  box-shadow: 0 0 45px rgba(0, 0, 0, .07);
  margin: 10px;
  border-radius: 5px;
}

/*.product-item img {
  transition: .5s;
}

.product-item:hover img {
  transform: scale(1.1);
}
*/

.product-item small a:hover {
  color: var(--primary) !important;
}

.product-item .text-primary {
  color: var(--primary) !important;
}


/*** Testimonial ***/
.testimonial-carousel .owl-item .testimonial-item img {
  width: 60px;
  height: 60px;
}

.testimonial-carousel .owl-item .testimonial-item,
.testimonial-carousel .owl-item .testimonial-item * {
  transition: .5s;
}

.testimonial-carousel .owl-item.center .testimonial-item {
  background: var(--primary) !important;
}

.testimonial-carousel .owl-item.center .testimonial-item * {
  color: #FFFFFF !important;
}

.testimonial-carousel .owl-item.center .testimonial-item i {
  color: var(--secondary) !important;
}

.testimonial-carousel .owl-dots{
  display: none;
}

.testimonial-carousel .owl-nav {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}

.text-black i{
  color: #000;
}

.testimonial-carousel .owl-nav .owl-prev,
.testimonial-carousel .owl-nav .owl-next {
  margin: 0 10px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  background-color: transparent;
  color: var(--dark);
  border: 2px solid var(--primary);
  border-radius: 50px;
  font-size: 20px;
  padding: 8px;
  transition: .5s;
}

.testimonial-carousel .owl-nav .owl-prev:hover,
.testimonial-carousel .owl-nav .owl-next:hover {
  color: #FFFFFF;
  background: var(--primary);
}


/*** Footer ***/
.bg-footer {
  background: #0d2538;
}

.footer {
  margin-top: 250px;
  padding-top: 10.5rem;
}

.footer-barra {
  width: 100%;
  height: 50vh;
  margin-top: -32.45rem;
  background:
    url(../imagens/bg-barra.png);
  background-position:
    center top,
    center center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

@media (min-width: 749px) and (max-width: 998px) {
  .footer-barra{
    margin-top: -24.9rem;
  }
}

.footer .btn.btn-social {
  margin-right: 5px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--light);
  border: 1px solid rgba(256, 256, 256, .1);
  border-radius: 40px;
  transition: .3s;
}

.footer .btn.btn-social:hover {
  color: var(--primary);
}

.footer .btn.btn-link {
  display: block;
  margin-bottom: 10px;
  padding: 0;
  text-align: left;
  color: var(--light);
  font-weight: normal;
  transition: .3s;
}

.footer .btn.btn-link::before {
  position: relative;
  content: "\f105";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin-right: 10px;
}

.footer .btn.btn-link:hover {
  letter-spacing: 1px;
  box-shadow: none;
  color: var(--secondary);
}

.footer .copyright {
  padding: 25px 0;
  font-size: 14px;
  border-top: 1px solid rgba(256, 256, 256, .1);
}

.footer .copyright a {
  color: var(--bg-secondary);
  list-style: none;
  text-decoration: none;
}

.footer .footer-menu a {
  list-style: none;
  text-decoration: none;
  margin-right: 15px;
  padding-right: 15px;
  border-right: 1px solid rgba(255, 255, 255, .1);
}

.footer .footer-menu a:last-child {
  margin-right: 0;
  padding-right: 0;
  border-right: none;
}

.footer .copyright a:hover,
.footer .footer-menu a:hover {
  color: var(--secondary);
}

.footer .copyright .logo-rodape {
  width: 30%;
}

@media (max-width: 600px) {
  .footer-barra {
    height: 10vh;
    margin-top: -14rem;
    padding-top: 0px;
  }
}



/*----------------------------------------------------
                LOGIN
-----------------------------------------------------*/
.row-login {
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
  background-image: url("../imagens/bg-carousel-3.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.row-login .form {
  display: flex;
  flex-direction: column;
}

.row-login .form-label {
  margin-top: 5px;
  padding: 5px;
  color: var(--bs-gray);
  font-family: "Arial", Helvetica, sans-serif;
}

.row-login .form-input {
  color: var(--bs-gray);
  padding: 1em;
  border: 1px solid var(--bs-pink);
  width: 100%;
  height: 40px;
  border-radius: 5px;
}

.row-login a{
  color: var(--primary);
  text-decoration: none;
  font-size: larger;
  transition: .3s;
}

.row-login a:hover{
  color: var(--dark);
}

.row-login .card {
  width: 40%;
  padding: 2em;
}

.row-login .btn-login {
  align-self: center;
  width: 70%;
  margin: 25px;
  padding: 10px 30px;
  border-radius: none !important;
}

/*.row-login .left-login {
  background-color: var(--bs-light);
}*/

.row-login .text-center {
  text-align: center;
}

.row-login .title-login {
  font-weight: bold;
}

.row-login .img-login {
  width: 600px;
  height: 600px;
}


@media (max-width: 748px) {
  .row-login{
    background-image: url("../imagens/login-fundo.jpeg");
    background-size: cover;
    background-position: left;
    background-repeat: no-repeat;
  }
  .row-login .card-title{
    display: none;
  }
  .row-login .img-login {
    display: none;
  }
  .row-login .card {
    width: 100%;
    padding: 2em;
  }
}
